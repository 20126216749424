import React from 'react';
import {Row, Col, Form, Input, Button} from 'antd';
import InputMask from 'react-input-mask';
import {PhoneFilled} from "@ant-design/icons";
import Layout from '../components/layout';

import faxIcon from '../images/icon-ui-fax.svg';
import locationIcon from '../images/icon-ui-location.svg';
import mailIcon from '../images/icon-ui-mail.svg';

// import './contact-us.less';

const ContactUsPage = () => {
const onSubmit = values => {
        const body = `Hi TicketTamer Team,

I am ${values.first_name} ${values.last_name}.
My phone number is ${values.phone} and my email is ${values.email}.

${values.message}

Thanks and Regards,`;
        
        const url = `mailto:info@tickettamer.com?subject=Contact Via Website&body=${body}`.replaceAll('\n', '%0D%0A');
        window.open(url);
    }

    return (
        <Layout title="Contact Us">
            <div className="ContactUs max-width-container">
                <div className="DisplayBlackLeft ContactUs__Title">Contact Us</div>
                <div className="LeadingBlackLeft ContactUs__Description">Question not answered yet? We are here to help!</div>
                <Row className="mb80" gutter={[, {xs: 80}]}>
                    <Col md={12}>
                        <div className="H1BlackLeft ContactUs__SubTitle">Drop a Message</div>
                        <Form onFinish={onSubmit} layout="vertical">
                            <div>
                                <Row gutter={20}>
                                    <Col md={12} xs={24}>
                                        <Form.Item name="first_name" label="First Name" rules={[{required: true, message: 'First name is required.'}]}>
                                            <Input placeholder="First name" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Form.Item name="last_name" label="Last Name" rules={[{required: true, message: 'Last name is required.'}]}>
                                            <Input placeholder="Last name" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Form.Item label="Phone Number" name="phone"  rules={[{required: true, message: 'Your phone number is required.'}]}>
                                    <InputMask mask={"(999) 999-9999"}>
                                        <Input placeholder="(___) ___-____" />
                                    </InputMask>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item name="email" label="Your Email" rules={[{required: true, message: 'Email is required.'}]}>
                                    <Input placeholder="example@abc.com" />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item name="message" label="Message" rules={[{required: true, message: 'The message is required.'}]}>
                                    <Input.TextArea rows={4} placeholder="note ..." />
                                </Form.Item>
                            </div>
                            <div>
                                <Button type="primary" htmlType="submit">Send Message</Button>
                            </div>
                        </Form>
                    </Col>
                    <Col md={{span: 8, offset: 4}}>
                        <div className="H1BlackLeft ContactUs__SubTitle">Contact Info</div>
                        <div className="ContactUs__Info__Item">
                            <PhoneFilled style={{fontSize: 20, marginRight: 18}} />
                            <a href="tel:3147284444"><div className="ButtonActionInactiveLeft">(314) 728-4444</div></a>
                        </div>
                        <div className="ContactUs__Info__Item">
                            <img src={locationIcon} alt="location" className="ContactUs__Info__Item__Icon" />
                            <div className="ButtonActionInactiveLeft">7217 Watson Rd., St. Louis, MO 63119</div>
                        </div>
                        <div className="ContactUs__Info__Item">
                            <img src={mailIcon} alt="mail" className="ContactUs__Info__Item__Icon" />
                            <a href="mailto:Info@TicketTamer.com"><div className="ButtonActionInactiveLeft">Info@TicketTamer.com</div></a>
                        </div>
                        <div className="ContactUs__Info__Item">
                            <img src={faxIcon} alt="fax" className="ContactUs__Info__Item__Icon" />
                            <div className="ButtonActionInactiveLeft">(844) 947-4402</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
}

export default ContactUsPage;
